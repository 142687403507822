import React from "react";
import {
  Box,
  Container,
  chakra,
  HStack,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import MasterLayout from "./master";
import { AiFillPlayCircle } from "react-icons/ai";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo/player_seo";

const ChapterLayout = (props) => {
  const { pageContext } = props;
  const { book } = pageContext;
  return (
    <MasterLayout>
      <Seo
        title={`${book.name} Audio Lectures - SunoKitaab`}
        description={`Audiobooks of ${book.name} Listen Now. NCERT Audiobooks, taught by brilliant teachers from all over India covering every education board including CBSE, WBBSE, RBSE, ICSE, UPMSP and more.`}
        bookImage={'https://s3sunokitaab.s3.ap-south-1.amazonaws.com/site-asset/logo.png'}
        headline={`${book.name} chapters - SunoKitaab`}
        url={`https://sunokitaab.com${props.location.pathname}`}
      />
      <Container py={8} maxW={"6xl"}>
        <a href="https://sunokitaab.com/app">
          <Box maxW={"full"}>
            <StaticImage src={"../images/long_width_app_banner.png"} />
          </Box>
        </a>
        <HStack justifyContent={"center"}>
          <List>
            {book.chapters.map((chapter) => (
              <Box
                maxW="container.md"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                p={6}
                my={4}
              >
                <a
                  href={`/${
                    chapter.hinglish_name
                      ? chapter.hinglish_name
                          .toLowerCase()
                          .split(".")
                          .join("")
                          .split(":")
                          .join("")
                          .split(" - ")
                          .join("-")
                          .split(" – ")
                          .join("-")
                          .split("|")
                          .join("")
                          .split(" ")
                          .join("-")
                          .split("--")
                          .join("-")
                      : chapter.name
                          .toLowerCase()
                          .split(".")
                          .join("")
                          .split(":")
                          .join("")
                          .split(" - ")
                          .join("-")
                          .split(" – ")
                          .join("-")
                          .split("|")
                          .join("")
                          .split(" ")
                          .join("-")
                          .split("--")
                          .join("-")
                  }`}
                >
                  <ListItem display={"flex"} alignItems={"center"}>
                    <ListIcon
                      as={AiFillPlayCircle}
                      fontSize={"1.6rem"}
                      color={"green"}
                    />
                    <chakra.h1 mx={4} fontSize={"xl"} fontWeight={"bold"}>
                      {chapter.name}
                    </chakra.h1>
                  </ListItem>
                </a>
              </Box>
            ))}
          </List>
        </HStack>
      </Container>
    </MasterLayout>
  );
};

export default ChapterLayout;
