import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import favicon from "../../images/favicon.ico";

const Seo = ({ description, lang, meta, title, url, headline, bookImage }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description,
            image
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      title={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: "og:url",
          content: url
        },
        {
          property: `og:image`,
          content: bookImage,
        },
        {
          property: `og:image:width`,
          content: "448",
        },
        {
          property: `og:image:height`,
          content: "640",
        },
        {
          property: `og:image:secure_url`,
          content: bookImage,
        },
        {
          name: `twitter:card`,
          content: `${metaDescription}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || `@Sunokitaab`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${url}"
            },
            "headline": "${headline}",
            "description": "${metaDescription}",
            "image": "${bookImage}",  
            "author": {
              "@type": "Organization",
              "name": "SunoKitaab",
              "url": "https://sunokitaab.com"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "SunoKitaab",
              "logo": {
                "@type": "ImageObject",
                "url": "https://s3sunokitaab.s3.ap-south-1.amazonaws.com/site-asset/logo.png"
              }
            },
            "datePublished": "2022-04-22"
        }`}
      </script>
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
